$ff: "EKRoumald", "Times New Roman", Times, "Noto Serif JP", "Georgia", "Garamond", serif;
$ffEN: 'SuisseIntl', "yu-gothic-pr6n", "Yu Gothic Medium";
$baseColor: #000;
$primaryColor: #EE3524;
$red: #EE3524;
$blue: #004BFF;
$limeGreen: #47BC00;
$accentColor: #CBFF37;
$darkGray: #474D52;
$gray: #9AA4AD;
$lightGray: #E8E8E8;
$superLightGray: #EEF1F6;
$transition01: all 500ms cubic-bezier(.215, .61, .355, 1);			
$containerPD: 6.4vw;
$mdContainerPD: 40px;
