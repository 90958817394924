@use "_variables.scss" as *;
@use "_mixin.scss" as *;
@use "_responsive.scss" as *;

.site-wrapper {
  overflow: hidden;
}

.site-trunk {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

.site-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(6px); /* 背後をぼかす */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  &--visible {
    visibility: visible;
    opacity: 1;
  }
  &__menu {
    display: flex;
    justify-content: center;
    font-size: 1.8rem;
    letter-spacing: 0.02em;
    @include respond-to(md) {
      font-size: 2.4rem;
    }
  }
  &__menu-item {
    padding: 0 16px;
    @include respond-to(md) {
      padding: 0 26px;
    }
  }
  a {
    &:link, &:visited {
      color: $primaryColor;
    }
  }
  .close-menu-button {
    position: fixed;
    top: 52px;
    right: $containerPD;
    z-index: 100;
    @include respond-to(md) {
      top: 7.5vh;
      right: $mdContainerPD;
    }
  }
}

.toggle-menu-button {
  position: fixed;
  top: 52px;
  right: $containerPD;
  z-index: 100;
  color: $primaryColor;
  font-size: 1.2rem;
  line-height: 1em;
  @include respond-to(md) {
    top: 7.5vh;
    right: $mdContainerPD;
    font-size: 1.6rem;
  }
}

.loader {
  width: 32px;        /* the size */
  padding: 2px;       /* the border thickness */
  background: $primaryColor; /* the color */
  aspect-ratio: 1;
  border-radius: 50%;
  mask: 
    conic-gradient(#0000,#000) subtract,
    linear-gradient(#000 0 0) content-box;
  box-sizing: border-box;
  animation: load 1s linear infinite;
}

@keyframes load {
  to{transform: rotate(1turn)}
}

.modal {
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(6px); /* 背後をぼかす */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  .loader {
    display: none;
  }
  &--visible {
    opacity: 1;
    visibility: visible;
    .loader {
      display: block;
    }
  }
  &__close-button {

  }
}