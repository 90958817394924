@mixin credit {
  font-size: 1rem;
  letter-spacing: 0.03em;
  line-height: 1.8em;
}

@mixin h1 {
  font-size: 2.4rem;
  line-height: normal;
  letter-spacing: 0.02em;
}

@mixin h2 {
  font-size: 2.2rem;
  font-style: normal;
  line-height: 111.111%;
  letter-spacing: 0.04em;
}

@mixin h3 {
  font-size: 2rem;
  font-style: normal;
  line-height: 111.111%;
  letter-spacing: 0.04em;
}

@mixin h4 {
  font-size: 1.8rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02em;
}

@mixin text-base {
  font-size: 16px;
}

@mixin text-sm {
  font-size: 1.4rem;
}

@mixin text-xsm {
  font-size: 1.2rem;
}
