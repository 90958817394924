@font-face {
  font-family: 'EKRoumald'; /* フォントの名前 */
  src: url('../fonts/EKRoumald-Roman.woff2') format('woff2'), /* WOFF2形式 */
       url('../fonts/EKRoumald-Roman.woff') format('woff');
  font-weight: normal; /* フォントの太さ */
  font-style: normal;  /* フォントのスタイル（通常） */
}

@font-face {
  font-family: 'EKRoumald'; /* フォントの名前 */
  src: url('../fonts/EKRoumald-Italic.woff2') format('woff2'), /* WOFF2形式 */
       url('../fonts/EKRoumald-Italic.woff') format('woff');
  font-weight: normal; /* フォントの太さ */
  font-style: italic;  /* フォントのスタイル（通常） */
}