@use "_variables.scss" as *;
@use "_responsive.scss" as *;
@use "_mixin.scss" as *;
@use "_keyframes.scss" as *;
@use "_fonts.scss" as *;


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display: block;
}

nav ul {
  list-style :none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align :middle;
}

picture {
  display: inline-block;
}


/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

::-webkit-input-placeholder,
::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: $primaryColor;
  font-family: $ff;
  @include respond-to(md) {
    font-size: 12px;
  }
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

input, select {
  vertical-align:middle;  
}

textarea,
select,
input[type=text],
input[type=search],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
input[type=date],
input[type=time] {
  @include text-base();
  display: block;
  padding: 0 20px 0 20px;
  width: 100%;
  // height: 48px;
  max-width: 480px;
  color: $darkGray;
  font-family: $ff;
  letter-spacing: 0.04em;
  line-height: 48px;
  border: 1px solid $lightGray;  
  border-radius: 8px;
  background-color: #fff;
  -webkit-appearance: none;  
  @include respond-to(md) {
    max-width: none;
    font-size: 16px;
  }
  &.has-error {
    color: #f7f7f7;
    background-color: $primaryColor;
  }
  &:focus {
    outline: none;
  }
}

input[type=time] {
  display: inline-block;
  padding: 0 8px 0 8px;
  line-height: 40px;
  width: auto;
}

[hidden] {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

input[type="radio"]{
  display: none;
  + .checkbox-backdrop {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 3px;
    width: 18px;
    height: 18px;
    border: 2px solid $gray;
    border-radius: 100px;
  }
  &:checked {
    + .checkbox-backdrop {
      border: 5px solid $primaryColor;
    }
  }
}

input[type="checkbox"]{
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 2px solid $gray;
  &:checked {
    background: $primaryColor url(../img/check.svg) no-repeat center center;
    border-color: $primaryColor;
  }
}

textarea {
  padding: 12px 20px;
  height: 160px;
  max-width: none;
  -webkit-appearance: none;
  line-height: 1.5em;
  border-radius: 8px;
  border: 1px solid $lightGray;  
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  @include text-base();
  // padding: 0 20px;
  // padding: 12px 20px 20px 20px;
  width: 100%;
  border: none;
  border: 1px solid $lightGray;  
  border-radius: 0;
  -webkit-appearance: none;
  background: var(--icon-arrow-down-xs) no-repeat right 10px center;
  &:focus {
    outline: none;
  }
}




/**
*
*	common properties
*
**/

html, body {
  touch-action: none; /* タッチ操作を無効化 */
  -webkit-overflow-scrolling: none; /* モバイルの慣性スクロールを無効化 */
}

html {
  color: $baseColor;
	font-size: 62.5%;
  // background-color: $superLightGray;
}

body {
  color: $baseColor;
	font-family: $ff;
	font-size: 16px;
  font-weight: 400;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  line-height:1;
}

img, a {
  border: none;
  outline: none;
}

label {
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
  letter-spacing: 0.03em;
  line-height: 1.4em;
  font-weight: 400;
}

p {
  line-height: 1.8em;
  letter-spacing: 0.02em;
}

main {
  display: block;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ol, ul {
  list-style: none;
}

a {
  &:link, &:visited {
    color: $baseColor;
    text-decoration: none;
    transition: opacity 500ms cubic-bezier(.215, .61, .355, 1);
  }
  &.hover--zoom {
    display: block;
    overflow: hidden;
    img {
      transition: $transition01;  
      transition-duration: 1000ms;  
    }
    &:hover, &:active {
      opacity: 1;
      img {
        transform: scale(1.04);        
      }
    }
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

svg {
  overflow: visible;
  overflow-y: visible;
  overflow-x: visible;
}

button {
  padding: 0;
  color: $baseColor;
  font-family: $ff;
  font-weight: 400;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.clear {
  clear: both;
}

ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.no-scroll {
	overflow-y: hidden;
}


.mt10 {margin-top: 10px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}
.mt60 {margin-top: 60px;}
.mt70 {margin-top: 70px;}
.mt80 {margin-top: 80px;}
.mt90 {margin-top: 90px;}
.mt100 {margin-top: 100px;}
.mt110 {margin-top: 110px;}
.mt120 {margin-top: 120px;}

.mb10 {margin-bottom: 10px;}
.mb20 {margin-bottom: 20px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb50 {margin-bottom: 50px;}
.mb60 {margin-bottom: 60px;}
.mb70 {margin-bottom: 70px;}
.mb80 {margin-bottom: 80px;}
.mb90 {margin-bottom: 90px;}
.mb100 {margin-bottom: 100px;}
.mb110 {margin-bottom: 110px;}
.mb120 {margin-bottom: 120px;}


.md\:mt10 {
  @include respond-to(md) {
    margin-top: 10px;
  }
}
.md\:mt20 {
  @include respond-to(md) {
    margin-top: 20px;
  }
}
.md\:mt30 {
  @include respond-to(md) {
    margin-top: 30px;
  }
}
.md\:mt40 {
  @include respond-to(md) {
    margin-top: 40px;
  }
}
.md\:mt50 {
  @include respond-to(md) {
    margin-top: 50px;
  }
}
.md\:mt60 {
  @include respond-to(md) {
    margin-top: 60px;
  }
}
.md\:mt70 {
  @include respond-to(md) {
    margin-top: 70px;
  }
}
.md\:mt80 {
  @include respond-to(md) {
    margin-top: 80px;
  }
}
.md\:mt90 {
  @include respond-to(md) {
    margin-top: 90px;
  }
}
.md\:mt100 {
  @include respond-to(md) {
    margin-top: 100px;
  }
}
.md\:mt110 {
  @include respond-to(md) {
    margin-top: 110px;
  }
}
.md\:mt120 {
  @include respond-to(md) {
    margin-top: 120px;
  }
}

.md\:mb10 {
  @include respond-to(md) {
    margin-bottom: 10px;
  }
}
.md\:mb20 {
  @include respond-to(md) {
    margin-bottom: 20px;
  }
}
.md\:mb30 {
  @include respond-to(md) {
    margin-bottom: 30px;
  }
}
.md\:mb40 {
  @include respond-to(md) {
    margin-bottom: 40px;
  }
}
.md\:mb50 {
  @include respond-to(md) {
    margin-bottom: 50px;
  }
}
.md\:mb60 {
  @include respond-to(md) {
    margin-bottom: 60px;
  }
}
.md\:mb70 {
  @include respond-to(md) {
    margin-bottom: 70px;
  }
}
.md\:mb80 {
  @include respond-to(md) {
    margin-bottom: 80px;
  }
}
.md\:mb90 {
  @include respond-to(md) {
    margin-bottom: 90px;
  }
}
.md\:mb100 {
  @include respond-to(md) {
    margin-bottom: 100px;
  }
}
.md\:mb110 {
  @include respond-to(md) {
    margin-bottom: 110px;
  }
}
.md\:mb120 {
  @include respond-to(md) {
    margin-bottom: 120px;
  }
}

.lg\:mt0 {
  @include respond-to(lg) {
    margin-top: 0;
  }
}
.lg\:mt10 {
  @include respond-to(lg) {
    margin-top: 10px;
  }
}
.lg\:mt20 {
  @include respond-to(lg) {
    margin-top: 20px;
  }
}
.lg\:mt30 {
  @include respond-to(lg) {
    margin-top: 30px;
  }
}
.lg\:mt40 {
  @include respond-to(lg) {
    margin-top: 40px;
  }
}
.lg\:mt50 {
  @include respond-to(lg) {
    margin-top: 50px;
  }
}
.lg\:mt60 {
  @include respond-to(lg) {
    margin-top: 60px;
  }
}
.lg\:mt70 {
  @include respond-to(lg) {
    margin-top: 70px;
  }
}
.lg\:mt80 {
  @include respond-to(lg) {
    margin-top: 80px;
  }
}
.lg\:mt90 {
  @include respond-to(lg) {
    margin-top: 90px;
  }
}
.lg\:mt100 {
  @include respond-to(lg) {
    margin-top: 100px;
  }
}
.lg\:mt110 {
  @include respond-to(lg) {
    margin-top: 110px;
  }
}
.lg\:mt120 {
  @include respond-to(lg) {
    margin-top: 120px;
  }
}

.lg\:mb10 {
  @include respond-to(lg) {
    margin-bottom: 10px;
  }
}
.lg\:mb20 {
  @include respond-to(lg) {
    margin-bottom: 20px;
  }
}
.lg\:mb30 {
  @include respond-to(lg) {
    margin-bottom: 30px;
  }
}
.lg\:mb40 {
  @include respond-to(lg) {
    margin-bottom: 40px;
  }
}
.lg\:mb50 {
  @include respond-to(lg) {
    margin-bottom: 50px;
  }
}
.lg\:mb60 {
  @include respond-to(lg) {
    margin-bottom: 60px;
  }
}
.lg\:mb70 {
  @include respond-to(lg) {
    margin-bottom: 70px;
  }
}
.lg\:mb80 {
  @include respond-to(lg) {
    margin-bottom: 80px;
  }
}
.lg\:mb90 {
  @include respond-to(lg) {
    margin-bottom: 90px;
  }
}
.lg\:mb100 {
  @include respond-to(lg) {
    margin-bottom: 100px;
  }
}
.lg\:mb110 {
  @include respond-to(lg) {
    margin-bottom: 110px;
  }
}
.lg\:mb120 {
  @include respond-to(lg) {
    margin-bottom: 120px;
  }
}

.list--inline {
  display: flex;
  flex-wrap: wrap;
}

.hidden,
.hide {
  display: none;
}

.video-js {
  .vjs-big-play-button {
    width: 50px;
    height: 52px;
    border: none;
    background: transparent var(--button-play-01) no-repeat center center;
    background-color: transparent !important;
    background-size: contain;
    .vjs-icon-placeholder {
      display: none;
    }
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-red {
  color: $red;
}

.text-blue {
  color: $blue;
}

.text-xlg {
  font-size: 2rem;
  @include respond-to(md) {
    font-size: 2.2rem;
  }
}

.img-rect--v {
  display: block;
  position: relative;
  padding-top: 125%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.img-square {
  display: block;
  position: relative;
  padding-top: 100%;
  border-radius: 8px;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 8px;
  }
}

.img-product {
  display: block;
  position: relative;
  padding-top: 125%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &.img-product--s {
    padding-top: 125%;
  }
}



.img-platinum {
  display: block;
  position: relative;
  padding-top: 58%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.md-visible {
  display: none;
  @include respond-to(md) {
    display: block;
  }
}

.md-visible--inline {
  display: none;
  @include respond-to(md) {
    display: inline-block;
  }
}

.md-hidden {
  @include respond-to(md) {
    display: none;
  }
}

.sm-hidden {
  @include respond-to(sm) {
    display: none;
  }
}

.sm-visible {
  display: none;
  @include respond-to(sm) {
    display: block;
  }  
}

.sm-visible--inline {
  display: none;
  @include respond-to(sm) {
    display: inline;
  }  
}

.error {
  margin-top: 4px;
  color: $red;
}

.dl--stack {
  @include text-base();
  dt {
    margin-bottom: 4px;
    line-height: 1.8em;
  }
  dd {
    margin-bottom: 30px;
  }
}

.stacks {
  .stack {
    margin-bottom: 16px;
  }
}

.font--bold {
  font-family: var(--font-b);
}

.uppercase {
  text-transform: uppercase;
}

.bgc--light-gray {
  background-color: $superLightGray;
}

.bdr--top {
  border-top: $lightGray;
}

.bdr--right {
  border-right: $lightGray;
}

.bdr--bottom {
  border-bottom: $lightGray;
}

.bdr--left {
  border-left: $lightGray;
}

.sticky-contents {
  width: 100%;
  position: sticky;
  top: 84px;
  @include respond-to(md) {
    position: static;
  }
  .page-title {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
  @include respond-to(sm) {
    margin-right: -6px;
    margin-left: -6px;
  }   
  &.row--center {
    justify-content: center;
  }   
  &.row--v-center {
    align-items: center;
  }   
  &.row--reverse {
    flex-direction: row-reverse;
  }       
}

.col {
  padding: 0 16px;
  @include respond-to(sm) {
    padding-right: 6px;
    padding-left: 6px;
  }  
}

.col--auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col--right {
  margin-left: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.col-md-1 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}

.col-md-2 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.col-md-3 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-md-4 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-md-5 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

.col-md-6 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-md-7 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.col-md-8 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.col-md-9 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.col-md-10 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.col-md-11 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

.col-md-12 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-lg-1 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}

.col-lg-2 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.col-lg-3 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-lg-4 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-lg-5 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

.col-lg-6 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-lg-7 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.col-lg-8 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.col-lg-9 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.col-lg-10 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.col-lg-11 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

.col-lg-12 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.span-xl-12 {
  width: auto;      
}
.span-xl-11 {
  width: calc(100vw / 12 * 11);
}
.span-xl-10 {
  width: calc(100vw / 12 * 10);
}
.span-xl-9 {
  width: calc(100vw / 12 * 9);
}
.span-xl-8 {
  width: calc(100vw / 12 * 8);
}
.span-xl-7 {
  width: calc(100vw / 12 * 7);
}
.span-xl-6 {
  width: calc(100vw / 12 * 6);
}
.span-xl-5 {
  width: calc(100vw / 12 * 5);
}  
.span-xl-4 {
  width: calc(100vw / 12 * 4);
}
.span-xl-3 {
  width: calc(100vw / 12 * 3);
}  
.span-xl-2 {
  width: calc(100vw / 12 * 2);
}

.scroll-container {
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 3px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-track{
    background-color: transparent;
    border: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $primaryColor;
    }
  }
}

.container {
  //overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 40px $containerPD;
  max-width: 1160px;
  @include respond-to(md) {
    padding: 56px $mdContainerPD;
  }
}

.container--full {
  max-width: none;
  padding: 0;
}

.container--fluid {
  max-width: none;
  @include respond-to(md) {
    padding-left: $mdContainerPD;
    padding-right: $mdContainerPD;
  }  
}

.container--md {
  max-width: 800px;
}

.container--sm {
  max-width: 480px;
}

.swiper-wrapper {
  height: auto;  
}

// .swiper-button-next {
//   padding-right: $containerPD;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   width: 60px;
//   height: auto;
//   &::after {
//     content: "";
//     display: block;
//     margin-left: auto;
//     width: 24px;
//     height: 24px;
//     background: url(../img/icon_arrow_right.svg) no-repeat center center;
//     background-size: contain;
//   }
// }

// .swiper-button-prev {
//   justify-content: flex-start;
//   padding-left: $containerPD;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   width: 60px;
//   height: auto;
//   &::after {
//     content: "";
//     display: block;
//     margin: 0;
//     width: 24px;
//     height: 24px;
//     background: url(../img/icon_arrow_left.svg) no-repeat center center;
//     background-size: contain;
//   }
// }

.swiper-horizontal { 
  .swiper-pagination {
    bottom: 32px;
    z-index: 1000;
    text-align: right;
    @include respond-to(md) {
      padding-right: 32px;
      bottom: 32px;
    }
  }
}

.swiper-pagination-bullet {
  width: 32px;
  height: 2px;
  background-color: #fff;
}

.prlx {
  opacity: 0;
  transition: opacity ease 0.8s;
  &.executed {
    opacity: 1;
  }
}