@charset "utf-8";
@use 'sass:math';
@use "_variables.scss" as *;
@use "_responsive.scss" as *;
@use "_mixin.scss" as *;
@use "_keyframes.scss" as *;
@use "_functions.scss" as *;
@use "_common.scss" as *;
@use "_components.scss" as *;


.site-title {
  position: fixed;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  text-align: center;
  @include respond-to(md) {
    top: 7.5vh;
  } 
  .logo {
    display: inline-block;
    width: 150px;
    @include respond-to(md) {
      width: auto;
    } 
  }
}

.posts {
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  margin-right: 0;
  margin-left: 0;
  .post {
    display: flex;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    &__inner {
      position: relative;
      &:hover {
        @media (hover: hover) {
          .post__tn {
            img {
              &:first-of-type {
                
                opacity: 1;
              }
              &:last-of-type {
                opacity: 0;
              }
            }
          }
        }
      }
    }
    &__tn {
      position: relative;
      aspect-ratio: 1025 / 672;
      margin: 0 auto;
      max-height: 200px;
      // max-width: 50%;
      @include respond-to(md) {
        // max-width: 160px;
      } 
      img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        max-width: none;
        height: 100%;
        transition: opacity 0.3s ease;
        &:first-of-type {
          opacity: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &:last-of-type {
          position: relative;
        }
      }
    }
    &__title {
      margin-top: 12px;
      color: $primaryColor;
      font-style: italic;
      font-size: 1.2rem;
      letter-spacing: 0.02em;
      @include respond-to(md) {
        margin-top: 25px;
        font-size: 1.6rem;
      } 
    }
  }
  .play-video-button {
    color: $primaryColor;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
    &::before {
      display: block;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      color: white;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      content: "";
      transition: background-color 0.3s ease;
    }
    &::after {
      // 5px x 5px rectangle
      display: inline-block;
      margin-left: 5px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 2.5px 0 2.5px 5px;
      border-color: transparent transparent transparent #FF4532;
      transform: translateY(-1px);
      content: "";
    }
    &:hover {
      @media (hover: hover) {
        &::before {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}

.interview {
  .container {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .video-player {
    position: relative;
    margin: 0 auto;
    width: 100%;
    @include respond-to(md) {
      width: 64%;
    }
    .loader-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    video {
      max-width: 100%;
      height: auto;
    }
  }
  .video-js {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    &.vjs-has-started {
      opacity: 1;
      visibility: visible;
    }
  }
  .modal__close-button {
    position: absolute;
    right: $containerPD;
    top: 40px;
    @include respond-to(md) {
      top: 105px;
    }
  }
}

.about {
  .container {
    position: relative;
    padding-top: 40px;
    max-width: 1068px;
    @include respond-to(md) {
      padding-top: 105px;
    }
  }
  &__close-button {
    display: block;
    position: sticky;
    right: $containerPD;
    top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    @include respond-to(md) {
      top: 105px;
      margin-bottom: 80px;
    }
  }
  &__content {
    margin: 0 auto;
    max-width: 650px;
    color: $primaryColor;
    p {
      margin-bottom: 2em;
      letter-spacing: 0.02em;
      line-height: unit-calculation(22, 16);
    }
  }
}